.header-profile-wrapper,
.header-contact-wrapper {
//  position: relative;
}

.header-profile-wrapper {
  
  @include breakpoint(small only) {
    padding: 0rem 1.5rem;    
  }
}

.header-contact-wrapper {
}

.header-profile-wrapper {
  
  @include breakpoint(small only){
    padding: 0 1rem;  
  }
}

.header-profile-pic2 {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 5;
}

.header-profile-info2 {
  position: absolute;
  padding-left: 3.5rem;
  margin-bottom: 0.4rem;
  
  @include breakpoint(small only) {
    padding-left: 3.1rem;
  }
}

.header-profile-name2 {
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0.45rem 0 0 0;
  color: $gray-900;
  letter-spacing: 0px;
}

.header-profile-profession2 {
  font-weight: 400;
  color: $gray-500;
}

.header-contact-info {
  margin-bottom: 0.4rem;
}

.header-contact-info-item {
  color: $gray-500;
  font-family: 'Karla', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  padding: 0.45rem 0 0 0;
  letter-spacing: -0.2px;
  text-align: right;
}

.header-contact-link2 {
  color: $gray-500;
}

//mobile styles

.header-profile-pic {
  position: absolute;
  width: 50px;
  height: 50px;
}

.header-profile-info {
//  position: absolute;
  padding-left: 4.1rem;
  
  @include breakpoint(small only) {
    margin-bottom: 0.5rem;
  }
}

.header-profile-name {
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0.25rem 0 0 0;
  color: $gray-900;
  letter-spacing: -0.5px;
}

.header-profile-profession {
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  color: $gray-500;
  padding: 0;
  letter-spacing: -0.2px;
}