.wtsbooks-img1 {
  width: 80%;
  
  @include breakpoint(small only) {
    width: 83%;
  }
  
  @include breakpoint(medium only) {
    width: 83%;
    padding-left: 1rem;
  }
  
  @include breakpoint(800) {
    padding-left: 1rem;
  }
  
  @include breakpoint(1024) {
    padding-left: 2rem;
  }
  
  @include breakpoint(1280) {
    padding-left: 1rem;
  }
}

.wtsbooks-img2 {
  width: 23%;
  position: absolute;

  @include breakpoint(medium only) {
    top: 6rem;
    right: 1.8rem;
  }
  
  @include breakpoint(small only) {
    top: 3rem;
    right: 1.25rem;
  }
  
  @include breakpoint(800) {
    top: 8rem;
    right: 1.8rem;
  }
  
  @include breakpoint(1024) {
    top: 10rem;
    right: 1.8rem;
  }
  
  @include breakpoint(1280) {
    top: 12rem;
    right: 0;
  }
}

.wtsbooks-img3 {
  width: 85%;
  
  @include breakpoint(medium only) {
    width: 90%;
    padding: 0 1.25rem 0;
  }
  
  @include breakpoint(small only) {
    width: 100%;
    padding: 3rem 0.625rem 0;
  }
  
  @include breakpoint(1000) {
    padding: 0 1rem 0;
  }
  
  @include breakpoint(1200) {
    padding: 1rem 0 0;
  }
}

.wtsbooks-section-desc {
  
  @include breakpoint(small only) {
    padding-top: 0;
  }
  
  @include breakpoint(medium only) {
    padding-top: 0rem;
    padding-right: 2rem;
  }
  
  @include breakpoint(700) {
    padding-top: 4rem;
    padding-right: 2rem;
  }
  
  @include breakpoint(1000) {
    padding-top: 8rem;
    padding-right: 2rem;
  }
  
  @include breakpoint(1200) {
    padding-top: 13rem;
    padding-right: 0;
    
  }
}

.wtsbooks-section-desc3 {
  padding-top: 16rem;
  padding-right: 5rem;
  
  @include breakpoint(medium only) {
    padding-top: 12rem;
    padding-right: 1.25rem;
    padding-bottom: 2rem;
  }
  
  @include breakpoint(small only) {
    padding-top: 1rem;
    padding-right: 1.25rem;
    padding-bottom: 2rem;
  }
}

.wtsbooks-img4 {
  margin-bottom: 5rem;
  
  @include breakpoint(medium only) {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  
  @include breakpoint(small only) {
    margin-bottom: 3rem;
  }
  
  @include breakpoint(1000) {
    margin-top: 0;
    margin-bottom: 3rem;
    padding-right: 0rem;
  }
  
}

.wtsbooks-section-desc2 {
  padding-top: 30rem;
  padding-right: 5rem;
  
  @include breakpoint(medium only) {
    padding-top: 16rem;
    padding-right: 1.25rem;
    padding-bottom: 3rem;
  }
  
  @include breakpoint(small only) {
    padding-top: 1rem;
    padding-right: 1.25rem;
    padding-bottom: 2rem;
  }
}

.wtsbooks-img5 {
  position: absolute;
  width: 55%;
  
  @include breakpoint(small only) {
    position: relative;
    width: 100%;
    padding: 0 0.625rem 1rem 0.625rem;
  }
}

.wtsbooks-img7 {
  position: absolute;
  width: 95%;
  top: 10rem;
  
  @include breakpoint(medium only) {
    top: 9rem;
  }
  
  @include breakpoint(small only) {
    position: relative;
    top: 0;
    width: 100%;
    padding-top: 5rem;
  }
}

.wtsbooks-img8 {
  position: absolute;
  width: 95%;
  top: -6rem;
  
  @include breakpoint(medium only) {
    position: relative;
    top: 0;
    width: 100%;
    margin-bottom: 1rem;
  }
  
  @include breakpoint(small only) {
    position: relative;
    top: 0;
    width: 100%;
  }
}

.wtsbooks-img9 {
  position: absolute;
  width: 95%;
  
  
  @include breakpoint(medium only) {
    position: relative;
    top: 0;
    width: 100%;
  }
  
  @include breakpoint(small only) {
    position: relative;
    top: 0;
    width: 100%;
  }
  
  @include breakpoint(1024) {
    top: 26rem;
  }
  
  @include breakpoint(1060) {
    top: 28rem;
  }
  
  @include breakpoint(1100) {
    top: 30rem;
  }
  
  @include breakpoint(1200) {
    top: 32rem;
  }
}

.wtsbooks-section-desc4 {
  padding-top: 9rem;
  padding-right: 5rem;
  
  @include breakpoint(medium only) {
    padding-top: 1rem;
  }
  
  @include breakpoint(small only) {
    padding-top: 1rem;
    padding-right: 1.25rem;
  }
}

.wtsbooks-logo {
  background-color: $gray-200;
  padding: 13rem 0;
  
  @include breakpoint(medium only) {
    padding: 9rem 0;
  }
  
  @include breakpoint(small only) {
    padding: 4rem 0;
  }
}

.wtsbooks-img10 {
  
  @include breakpoint(medium only) {
    width: 60%;
  }
  
  @include breakpoint(small only) {
    width: 75%;
  }
}

.wtsbooks-logo-light {
  position: relative;
  padding-left: 2rem;
  
  @include breakpoint(medium only) {
    padding: 0 1.25rem;
  }
  
  @include breakpoint(small only) {
    padding: 0 1.25rem;
  }
}

.wtsbooks-logo-dark {
  padding-left: 0;
  padding-top: 15rem;
  
  
  
  @include breakpoint(small only) {
    padding: 0 1.25rem;
  }
}

.wtsbook-logo-img1 {
  width: 100%;
  
  @include breakpoint(small only) {
    padding-top: 1.5rem;
  }
}

.wtsbook-logo-img2 {
  width: 100%;
  position: absolute;
  top: -3rem;
  
  @include breakpoint(medium only) {
    top: -2rem;
  }
  
  @include breakpoint(small only) {
    position: relative;
    top: 0;
    padding-top: 1.5rem;
  }
}

.wtsbooks-img-wrapper1 {
  position: relative;
  
  @include breakpoint(medium only){
    padding: 0 1.25rem;
  }
  
  @include breakpoint(small only){
    padding-left: 1.25rem;
  }
}

.wtsbooks-img-wrapper2 {
  position: relative;
  
  @include breakpoint(medium only){
    padding: 0 1.25rem;
  }
  
  @include breakpoint(small only){
    padding-right: 1.25rem;
  }
}