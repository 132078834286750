.scroll-topbar {
  align-items: center;
  background: none;
  max-width: none;
  overflow: hidden;
  position: fixed;
  padding: 1rem 1.25rem;
  background-color: none;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: transform 0.25s ease, background-color 0.5s ease;
  width: 100%;
  z-index: 99999;
  
  @include breakpoint(small only) {
    padding: 1rem 0.9375rem;
  }
  
  @include breakpoint(medium only) {
    padding: 1rem 0.9375rem;
  }
  
  @include breakpoint(1024) {
    padding: 1rem 0.9375rem;
  }
  
  &.hide-nav {
    transform: translateY(-100%);
    transition: transform 0.25s ease;
  }
  
  &.background {
    background-color: $white;
  }
  
  &.top-of-page {
    
    @include breakpoint (medium only) {
      padding: 2rem 0.9375rem 1rem 0.9375rem;
    }
    
    @include breakpoint (1024) {
      padding: 3rem 0.9375rem 1rem 0.9375rem;
    }
    
    @include breakpoint (1200) {
      padding: 5rem 0.9375rem 1rem 0.9375rem;
    }
    
    @include breakpoint (1300) {
      padding: 5rem 0 1rem 0;
    }
    
    transition: padding 0.25s ease;
  }
}


.work-header {
  letter-spacing: -0.2px;
}

.work-section-partial {
  
  &.secondary {
    padding: 5rem 0 0 0;
    
    @include breakpoint(medium only) {
      padding: 3rem 0 0 0;
    }
    
    @include breakpoint(small only) {
      padding-top: 0rem;
    } 
  }  
}

.work-second-img-wrapper {
  position: relative;
}

.work-second-column {
//  padding-left: 1rem;
  width: 98%;
  
  @include breakpoint(medium only) {
//    padding-left: 1rem;
    width: 100%;
  } 
  
  @include breakpoint(small only) {
//    padding-left: 0.75rem;
    padding-top: 2rem;
    width: 100%;
  } 
 
}

.work-first-column {
  position: absolute;
  top: -19.5rem;
  width: 94.5%;
  
  &.last {
    padding-bottom: 7rem;
    
    @include breakpoint(medium only) {
      padding-bottom: 4rem;
    }
    
    @include breakpoint(small only) {
      padding-bottom: 3rem;
    } 
  }
  
  @include breakpoint(medium only) {
    top: -8rem;
    width: 92%;
//    padding-right: 1rem;
  } 
  
  @include breakpoint(small only) {
    position: relative;
    top: 0;
    padding-right: 0;
    padding-top: 2rem;
    width: 100%;
  } 
}

.work-info {
  margin-top: 1rem;
}

.work-name {
  font-family: 'Karla', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.35;
  padding: 0.25rem 0 0 0;
  color: $gray-900;
  
  @include breakpoint(medium only) {
    font-size: 1.25rem;
    padding: 0.15rem 0 0 0;
  }
  
  @include breakpoint(small only) {
    font-size: 1rem;
    padding: 0;
  } 
}

.work-meta {
  font-family: 'Karla', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.35;
  color: $gray-600;
  padding: 0;
  
  @include breakpoint(medium only) {
    font-size: 1.25rem;
  }
  
  @include breakpoint(small only) {
    font-size: 1rem;
  } 
}

.header-hero {
  padding-top: 5rem;
  padding-bottom: 3rem;
  z-index: 1;
  
  @include breakpoint(small only) {
    padding-top: 2rem;
  }
  
  @include breakpoint(medium only) {
    padding-top: 4rem;
  }
}

.header-hero-text {
  color: $gray-900;
  font-weight: 400;
  letter-spacing: -1px;
  padding-right: 1rem;
  
  @include breakpoint(medium only) {
    padding-right: 0;
  }
  
  @include breakpoint(small only) {
    padding-right: 0;
  }
}

.header-section {
  padding: 8rem 0 18rem 0;
  
  @include breakpoint(medium only) {
    padding: 4rem 0 6rem 0;
  } 
  
  @include breakpoint(small only) {
    padding: 0rem 0 4rem 0;
  }
}

.img-link {
  display: block;
  background-color: $white;
}

.img-link img {
//  -webkit-transform: scale(1);
//  transform: scale(1);
  opacity: 1.0;
  -webkit-transition: .3s ease;
  transition: opacity .3s ease;
}

.img-link:hover img {
//  -webkit-transform: scale(1.015);
//  transform: scale(1.015);
  opacity: 0.92;
  transition: opacity .3s ease;
}

.more-work1,
.more-work2 {
  display: block;
}