.zurb-img1 {
  width: 80%;
  
  @include breakpoint(small only) {
    width: 83%;
  }
  
  @include breakpoint(medium only) {
    width: 83%;
    padding-left: 1rem;
  }
  
  @include breakpoint(800) {
    padding-left: 1rem;
  }
  
  @include breakpoint(1024) {
    padding-left: 2rem;
  }
  
  @include breakpoint(1280) {
    padding-left: 1rem;
  }
}

.zurb-img2 {
  width: 23%;
  position: absolute;

  @include breakpoint(medium only) {
    top: 6rem;
    right: 1.8rem;
  }
  
  @include breakpoint(small only) {
    top: 2.5rem;
    right: 1.25rem;
  }
  
  @include breakpoint(800) {
    top: 8rem;
    right: 1.8rem;
  }
  
  @include breakpoint(1024) {
    top: 10rem;
    right: 1.8rem;
  }
  
  @include breakpoint(1280) {
    top: 12rem;
    right: 0;
  }
}

.zurb-section-partial {
  padding: 0 0 12rem 0;
  
  @include breakpoint(medium only){
    padding: 0 0 5rem 0;
  }
  
  @include breakpoint(small only) {
    padding: 0 0 3rem 0;
  }
}

.zurb-img-wrapper {
  height: 1050px;
  position: relative;
}

.zurb-img7 {
  
  @include breakpoint(small only) {
    padding: 0 0.625rem;
  }
  
  @include breakpoint(medium only) {
    padding: 0 1rem;
  }
  
  @include breakpoint(800) {
    padding: 0 1rem;
  }
  
  @include breakpoint(1000) {
    padding: 0 1rem;
  }
  
  @include breakpoint(1280) {
    padding: 0;
  }
}

.zurb-img8 {
  position: absolute;
  width: 100%;
  top: 20rem;
  left: -18rem;
}

.zurb-img9 {
  position: absolute;
  width: 100%;
  top: 40rem;
  left: 0rem;
}

.zurb-section-desc {
  padding-top: 8rem;
  padding-right: 5rem;
  
  @include breakpoint(medium only){
    padding-top: 1rem;
    padding-right: 1.25rem;
  }
  
  @include breakpoint(small only) {
    padding-top: 0;
    padding-right: 1.25rem;
  }
}

.zurb-img11 {
  position: absolute;
  width: 95%;
  top: 3rem;
  
  @include breakpoint(medium only) {
  }
  
  @include breakpoint(small only) {
    position: relative;
    width: 100%;
    top: 0rem;
    padding-top: 6rem;
  }
}

.zurb-img12 {
  position: absolute;
  width: 95%;
  top: -6rem;
  
  @include breakpoint(medium only) {
    position: relative;
    width: 100%;
    top: 0rem;
    padding-top: 0;
  }
  
  @include breakpoint(small only) {
    position: relative;
    width: 100%;
    top: 0rem;
    padding-top: 1rem;
  }
}

.zurb-img-wrapper1 {
  position: relative;
  
  @include breakpoint(medium only){
    padding: 0 1.25rem;
  }
  
  @include breakpoint(small only){
    padding-left: 1.25rem;
  }
}

.zurb-img-wrapper2 {
  position: relative;
  
  @include breakpoint(medium only){
    padding: 0 1.25rem;
  }
  
  @include breakpoint(small only){
    padding-right: 1.25rem;
  }
}