.samsung-desc {
  
  @include breakpoint(medium only) {
    padding-right: 2rem;
  } 
  
  @include breakpoint(small only) {
    padding-top: 1rem;
  }
}

.samsung-img1 {
  
  @include breakpoint(small only) {
    padding: 0 0.625rem;
  } 
  
  @include breakpoint(medium only) {
    padding: 0 1rem;
  }
  
  @include breakpoint(1024) {
    padding: 0 1rem;
  } 
  
  @include breakpoint(1200) {
    padding: 0;
  } 
}

.samsung-desc2 {
  
  @include breakpoint(medium only) {
    padding-left: 1rem;
  }
}

.alt-wrapper {
  padding: 3.5rem 3rem 0 1rem;
  
  @include breakpoint (small only) {  
    padding: 2rem 1.25rem 0 1.25rem;
  }
}

.alt-title {
  color: $true-black;
  font-weight: 800;
  margin-bottom: 0.5rem;
}

.alt-body-text {
  font-size: 0.9rem;
  line-height: 1.5;
  color: $gray-600;
  
  @include breakpoint (small only) {  
    font-size: 0.8rem;
  }
}

.alt-list {
  
  li {
    font-size: 0.9rem;
    line-height: 1.5;
    color: $gray-600;
    
    @include breakpoint (small only) {  
      font-size: 0.8rem;
    }
  }
}

.samsung-img-wrapper {
  padding-top: 8rem;
  
  @include breakpoint (medium only) {  
    padding: 3rem 0.625rem 0;
  }
  
  @include breakpoint (small only) {  
    padding-top: 2rem;
  }
}

.samsung-img-wrapper1 {
  padding: 8rem 0;
  
  @include breakpoint (medium only) {  
    padding: 4rem 0;
  }
  
  @include breakpoint (small only) {  
    padding: 2rem 0;
  }
}

.samsung-img-section {
  
  @include breakpoint(medium only) {
    padding: 0 1rem;
  }
  
  @include breakpoint(small only) {
    padding: 0 0.625rem;
  }
}

.samsung-img8 {
  
  @include breakpoint(small only) {
    padding: 0 0.4rem;
  }
  
  @include breakpoint(medium only) {
    padding: 0 1rem;
  }
}

.samsung-img9 {
  
  @include breakpoint(small only) {
    padding: 0 0.4rem;
  }
  
  @include breakpoint(medium only) {
    padding: 0 1rem;
  }
}

.samsung-img10 {
  
  @include breakpoint(small only) {
    padding: 0 0.4rem;
  }
  
  @include breakpoint(medium only) {
    padding: 0 1rem;
  }
}

.samsung-img11 {
  
  @include breakpoint (small only) {  
    padding: 0 0.625rem;
  }
  
  @include breakpoint (medium only) {  
    padding: 0;
  }
  
  @include breakpoint (large only) {
    padding: 0;
  }
}

.samsung-img12 {
  
  @include breakpoint (medium only) {  
    padding: 0;
  }
  
  @include breakpoint (small only) {  
    padding: 0 0.625rem;
  }
}