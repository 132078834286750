body {
  overflow-x: hidden;
  
  @include breakpoint(small only) {
    padding-bottom: 2rem;
  }
  
  @include breakpoint(medium only) {
    padding-bottom: 3rem;
  }  
  
  @include breakpoint (1024) {
    padding-bottom: 4rem;
  }

  @include breakpoint (1200) {
    padding-bottom: 5rem;
  }
}

.particle-header {
  position: relative;
  
  @include breakpoint(small only) {
    padding-top: 5rem;
  }
  
  @include breakpoint(medium only) {
    padding-top: 6rem;
  } 
  
  @include breakpoint (1024) {
    padding-top: 8rem;
  }

  @include breakpoint (1200) {
    padding-top: 10rem;
  }
}

.particles-stage {
  height: 600px;
  width: 750px;
  // Safari Fix
  right: 0;
  top: 0;
  opacity: 0;
  z-index: -9999;
  position: absolute;
  transition: opacity 2s ease;
  transition-delay: 1s;

  &.is-loaded {
    opacity: 1;
  }
  
  @include breakpoint(medium only) {
    width: 450px;
  }
  
  @include breakpoint(small only) {
    width: 250px;
  }
}

.work-details-header {
  margin-top: 16rem;
  
  @include breakpoint(medium only) {
    margin-top: 11rem;
    margin-bottom: 0;
  } 
  
  @include breakpoint(small only) {
    margin-top: 7rem;
    padding-left: 0rem;
    margin-bottom: 3rem;
  } 
}

.work-details-header-info {
}

.title {
  color: $gray-900;
  font-weight: 600;
  letter-spacing: -0.5px;
  
  @include breakpoint(small only) {
    font-size: 1.5rem;
  }
  
  @include breakpoint(medium only) {
    font-size: 1.5rem;
  }
  
  @include breakpoint(800) {
    font-size: 1.7rem;
  }
  
  @include breakpoint(1025) {
    font-size: 2rem;
  }
}

.subtitle {
  color: $gray-500;
  font-weight: 400;
  margin-bottom: 5rem;
  letter-spacing: -0.5px;
  font-size: 2rem;
  line-height: 1.2;
  
  @include breakpoint(small only) {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  
  @include breakpoint(medium only) {
    font-size: 1.5rem;
  }
  
  @include breakpoint(800) {
    font-size: 1.7rem;
  }
  
  @include breakpoint(1025) {
    font-size: 2rem;
  }
}

.hero {
  margin-bottom: 1rem;
  
  &.plaid-consumer {
    background: -webkit-linear-gradient(left, rgba(17,17,17,1), rgba(17,17,17,1) 80%, transparent 80%, transparent 100%);
    background: -o-linear-gradient(left, rgba(17,17,17,1), rgba(17,17,17,1) 80%, transparent 80%, transparent 100%);
    background: linear-gradient(to right, rgba(17,17,17,1), rgba(17,17,17,1) 80%, transparent 80%, transparent 100%);
    
    @include breakpoint(small only){
      background: -webkit-linear-gradient(bottom, rgba(17,17,17,1), rgba(17,17,17,1) 80%, transparent 80%, transparent 100%);
      background: -o-linear-gradient(bottom, rgba(17,17,17,1), rgba(17,17,17,1) 80%, transparent 80%, transparent 100%);
      background: linear-gradient(to top, rgba(17,17,17,1), rgba(17,17,17,1) 80%, transparent 80%, transparent 100%);
    }
  }

  &.wtsbooks {
    background: -webkit-linear-gradient(left, rgba(255,165,20,1), rgba(255,165,20,1) 80%, transparent 80%, transparent 100%);
    background: -o-linear-gradient(left, rgba(255,165,20,1), rgba(255,165,20,1) 80%, transparent 80%, transparent 100%);
    background: linear-gradient(to right, rgba(255,165,20,1), rgba(255,165,20,1) 80%, transparent 80%, transparent 100%);
    
    @include breakpoint(small only){
      background: -webkit-linear-gradient(bottom, rgba(255,165,20,1), rgba(255,165,20,1) 80%, transparent 80%, transparent 100%);
      background: -o-linear-gradient(bottom, rgba(255,165,20,1), rgba(255,165,20,1) 80%, transparent 80%, transparent 100%);
      background: linear-gradient(to top, rgba(255,165,20,1), rgba(255,165,20,1) 80%, transparent 80%, transparent 100%);
    }
  }
  
  &.foundation {
    background: -webkit-linear-gradient(left, rgba(28,41,46,1), rgba(28,41,46,1) 80%, transparent 80%, transparent 100%);
    background: -o-linear-gradient(left, rgba(28,41,46,1), rgba(28,41,46,1) 80%, transparent 80%, transparent 100%);
    background: linear-gradient(to right, rgba(28,41,46,1), rgba(28,41,46,1) 80%, transparent 80%, transparent 100%);
    
    @include breakpoint(small only){
      background: -webkit-linear-gradient(bottom, rgba(28,41,46,1), rgba(28,41,46,1) 80%, transparent 80%, transparent 100%);
      background: -o-linear-gradient(bottom, rgba(28,41,46,1), rgba(28,41,46,1) 80%, transparent 80%, transparent 100%);
      background: linear-gradient(to top, rgba(28,41,46,1), rgba(28,41,46,1) 80%, transparent 80%, transparent 100%);
    }
  }
  
  &.samsung {
    background: -webkit-linear-gradient(left, rgba(30,116,177,1), rgba(0,187,192,1) 80%, transparent 80%, transparent 100%);
    background: -o-linear-gradient(left, rgba(30,116,177,1), rgba(0,187,192,1) 80%, transparent 80%, transparent 100%);
    background: linear-gradient(to right, rgba(30,116,177,1), rgba(0,187,192,1) 80%, transparent 80%, transparent 100%);
    
    @include breakpoint(small only){
      background: -webkit-linear-gradient(bottom, rgba(30,116,177,1), rgba(0,187,192,1) 80%, transparent 80%, transparent 100%);
      background: -o-linear-gradient(bottom, rgba(30,116,177,1), rgba(0,187,192,1) 80%, transparent 80%, transparent 100%);
      background: linear-gradient(to top, rgba(30,116,177,1), rgba(0,187,192,1) 80%, transparent 80%, transparent 100%);
    }
  }
  
  &.zurb {
    background: -webkit-linear-gradient(left, rgba(184,213,0,1), rgba(184,213,0,1) 80%, transparent 80%, transparent 100%);
    background: -o-linear-gradient(left, rgba(184,213,0,1), rgba(184,213,0,1) 80%, transparent 80%, transparent 100%);
    background: linear-gradient(to right, rgba(184,213,0,1), rgba(184,213,0,1) 80%, transparent 80%, transparent 100%);
    
    @include breakpoint(small only){
      background: -webkit-linear-gradient(bottom, rgba(184,213,0,1), rgba(184,213,0,1) 80%, transparent 80%, transparent 100%);
      background: -o-linear-gradient(bottom, rgba(184,213,0,1), rgba(184,213,0,1) 80%, transparent 80%, transparent 100%);
      background: linear-gradient(to top, rgba(184,213,0,1), rgba(184,213,0,1) 80%, transparent 80%, transparent 100%);
    }
  }
  
  &.myvotetoday {
    background: -webkit-linear-gradient(left, rgba(219,50,49,1), rgba(219,50,49,1) 80%, transparent 80%, transparent 100%);
    background: -o-linear-gradient(left, rgba(219,50,49,1), rgba(219,50,49,1) 80%, transparent 80%, transparent 100%);
    background: linear-gradient(to right, rgba(219,50,49,1), rgba(219,50,49,1) 80%, transparent 80%, transparent 100%);
    
    @include breakpoint(small only){
      background: -webkit-linear-gradient(bottom, rgba(219,50,49,1), rgba(219,50,49,1) 80%, transparent 80%, transparent 100%);
      background: -o-linear-gradient(bottom, rgba(219,50,49,1), rgba(219,50,49,1) 80%, transparent 80%, transparent 100%);
      background: linear-gradient(to top, rgba(219,50,49,1), rgba(219,50,49,1) 80%, transparent 80%, transparent 100%);
    }
  }
}

.hero-text-wrapper {
  position: relative;
  padding: 0;
  
  @include breakpoint(medium only) {
    height: 320px;
    padding: 0;
  }
  
  @include breakpoint(small only) {
    height: auto;
  }
  
  @include breakpoint(640) {
    height: 340px;
  }
  
  @include breakpoint(800) {
    height: 360px;
  }
  
  @include breakpoint(1025) {
    height: 420px;
  }
  
  @include breakpoint(1150) {
    height: 460px;
  }
}

.hero-text {
  position: absolute;
  bottom: 3rem;
  font-size: 2rem;
  line-height: 1.3;
  color: $white;
  padding-right: 6rem;
  padding-left: 1rem;
  
  &.plaid-consumer {
    color: lighten(#111111,40);
  }

  &.zurb {
    color: lighten(#c0d922,40);
  }
  
  &.samsung {
    color: lighten(#0F98B9,40);
  }
  
  &.foundation {
    color: lighten(#212729,30);
  }
  
  &.wtsbooks {
    color: lighten(#FFA616,35);
  }
  
  &.myvotetoday {
    color: lighten(#DB3231,35);
  }
  
  @include breakpoint(medium only) {
    font-size: 1.5rem;
    bottom: 2rem;
    padding: 0 2rem 0 1rem;
  }
  
  @include breakpoint(800) {
    font-size: 1.7rem;
  }
  
  @include breakpoint(1025) {
    font-size: 2rem;
  }
}

.hero-text-mobile {

  @include breakpoint(small only) {
    color: $white;
    font-size: 1.5rem;
    line-height: 1.3;
    bottom: auto;
    padding: 0 0.625rem 2rem;
    position: inherit;
  }
  
  &.plaid-consumer {
    color: lighten(#111111,40);
  }

  &.zurb {
    color: lighten(#c0d922,40);
  }
  
  &.samsung {
    color: lighten(#0F98B9,40);
  }
  
  &.foundation {
    color: lighten(#212729,30);
  }
  
  &.wtsbooks {
    color: lighten(#FFA616,35);
  }
  
  &.myvotetoday {
    color: lighten(#DB3231,35);
  }
}

.hero-text .hero-link {
  color: $true-white;
  opacity: 1.0;
  transition: opacity 0.3s ease;
}

.hero-text-mobile .hero-link {
  color: $true-white;
  opacity: 1.0;
  transition: opacity 0.3s ease;
}

.hero-link:hover,
.hero-link:focus {
  opacity: 1.0;
  transition: opacity 0.3s ease;
}

.hero-img-wrapper {
}

.hero-img {
  position: absolute;
  top: -11rem;
  
  @include breakpoint(medium only) {
    padding-right: 2rem;
    top: -9rem;
  }
  
  @include breakpoint(small only) {
    position: inherit;
    padding: 0 0.1rem 2.5rem;
  }
  
  @include breakpoint (800) {
    padding-right: 2rem;
  }
}

.intro-meta {
  
  @include breakpoint(small only) {
    padding-left: 0.625rem;
  }
  
  @include breakpoint(medium only) {
    padding-left: 0.9375rem;
  }
  
  @include breakpoint(800) {
    padding-left: 0.9375rem;
  }
  
  @include breakpoint(1024) {
    padding-left: 0.9375rem;
  }
  
  @include breakpoint(1220) {
    padding-left: 0;
  }
}

.section {
  padding: 8rem 1rem;
  
  @include breakpoint(medium only) {
    padding: 6rem 1rem;
  } 
  
  @include breakpoint(small only) {
    padding: 3rem 0;
  }
}

.section-partial {
  padding: 0 0 8rem 0;
  
  @include breakpoint(medium only){
    padding: 0 0 4rem 0;
  }
  
  @include breakpoint(small only){
    padding: 0 0 3rem 0;
  }
}

.section-title {
  margin-bottom: 1rem;
  
  @include breakpoint(small only){
    padding: 0 0.625rem;
  }

  &.additional-title {
    padding-top: 2rem;
  }
}

.intro-meta-list {
  
  @include breakpoint(small only){
    padding: 0 0.625rem;
  }
}

.intro-meta-list li {
  
  @include breakpoint(small only){
    font-size: 1rem;  
  }
}

.intro-text {
  
  @include breakpoint(medium only){
    padding-right: 1rem;
  }
  
  @include breakpoint(small only){
    margin-top: 1rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.img-wrapper {
  position: relative;
  
  @include breakpoint(medium only){
    padding: 0 1.25rem;
  }
  
  @include breakpoint(small only){
    padding: 0 1.25rem;
  }
}

.body-text {
  
  @include breakpoint(small only){
    padding: 0 0.625rem; 
  } 
}

.bg-color {
  
  &.gray-partial {
    background: -webkit-linear-gradient(bottom, $gray-200, $gray-200 85%, transparent 85%, transparent 100%);
    background: -o-linear-gradient(bottom, $gray-200, $gray-200 85%, transparent 85%, transparent 100%);
    background: linear-gradient(to top, $gray-200, $gray-200 85%, transparent 85%, transparent 100%);
  }
  
  &.myvotetoday-full {
    
    @include breakpoint(small only) {
      background: -webkit-linear-gradient(bottom, $gray-200, $gray-200 100%, transparent 100%, transparent 100%);
    }
    
    @include breakpoint(640) {
      background: -webkit-linear-gradient(bottom, $gray-200, $gray-200 100%, transparent 100%, transparent 100%);
    }
    
    @include breakpoint(800) {
      background: -webkit-linear-gradient(bottom, $gray-200, $gray-200 85%, transparent 85%, transparent 100%);
    }
  }
  
  &.gray-partial-top {
    background: -webkit-linear-gradient(top, $gray-200, $gray-200 60%, transparent 60%, transparent 100%);
    background: -o-linear-gradient(top, $gray-200, $gray-200 60%, transparent 60%, transparent 100%);
    background: linear-gradient(to bottom, $gray-200, $gray-200 60%, transparent 60%, transparent 100%);
  }
  
  &.gray-full {
    background-color: $gray-200;
  }
  
  &.samsung-gray-partial {
    background: -webkit-linear-gradient(bottom, $gray-200, $gray-200 50%, transparent 50%, transparent 100%);
    background: -o-linear-gradient(bottom, $gray-200, $gray-200 50%, transparent 50%, transparent 100%);
    background: linear-gradient(to top, $gray-200, $gray-200 50%, transparent 50%, transparent 100%);
  }
  
  &.zurb-gray-partial {
    background: -webkit-linear-gradient(top, $gray-200, $gray-200 30%, transparent 30%, transparent 100%);
    background: -o-linear-gradient(top, $gray-200, $gray-200 30%, transparent 30%, transparent 100%);
    background: linear-gradient(to bottom, $gray-200, $gray-200 30%, transparent 30%, transparent 100%);
  }

  &.plaid-consumer-gray-partial {
    background: -webkit-linear-gradient(bottom, $gray-200, $gray-200 50%, transparent 50%, transparent 100%);
    background: -o-linear-gradient(bottom, $gray-200, $gray-200 50%, transparent 50%, transparent 100%);
    background: linear-gradient(to top, $gray-200, $gray-200 50%, transparent 50%, transparent 100%);
  }
}


.callout-section {
  padding: 6rem 0;
  
  @include breakpoint(medium only) {
    padding: 4rem 1rem;
  } 
  
  @include breakpoint(small only) {
    padding: 2rem 0;
  }
  
}

.morework-header {
  padding-top: 12rem;
  
  @include breakpoint(medium only) {
    padding-top: 8rem;
  } 
  
  @include breakpoint(small only) {
    padding-top: 2rem;
  }
}

//.lazy-hidden {
//  opacity: 0;
//}
//
//.animated {
//  -webkit-animation-duration: 1s;
//  animation-duration: 1s;
//  -webkit-animation-fill-mode: both;
//  animation-fill-mode: both;
//}
//
//@-webkit-keyframes fadeInUp {
//  0% {
//    opacity: 0;
//    -webkit-transform: translateY(20px);
//    transform: translateY(20px);
//  }
//
//  100% {
//    opacity: 1;
//    -webkit-transform: translateY(0);
//    transform: translateY(0);
//  }
//}
//
//@keyframes fadeInUp {
//  0% {
//    opacity: 0;
//    -webkit-transform: translateY(20px);
//    -ms-transform: translateY(20px);
//    transform: translateY(20px);
//  }
//
//  100% {
//    opacity: 1;
//    -webkit-transform: translateY(0);
//    -ms-transform: translateY(0);
//    transform: translateY(0);
//  }
//}
//
//.fadeInUp {
//  -webkit-animation-name: fadeInUp;
//  animation-name: fadeInUp;
//}