.plaid-consumer-img1 {
  width: 92%;
  
  @include breakpoint(small only) {
    width: 88%;
    padding-bottom: 1rem;
  }
  
  @include breakpoint(medium only) {
    width: 88%;
    padding-left: 1rem;
  }
  
  @include breakpoint(800) {
    padding-left: 1rem;
  }
  
  @include breakpoint(1024) {
    padding-left: 1rem;
    width: 88%;
  }
  
  @include breakpoint(1280) {
    padding-left: 1rem;
    width: 92%;
  }
}

.plaid-consumer-img2 {
  width: 30%;
  position: absolute;

  @include breakpoint(medium only) {
    top: 2.5rem;
    right: 1.8rem;
  }
  
  @include breakpoint(small only) {
    top: 1rem;
    right: 1.25rem;
  }
  
  @include breakpoint(800) {
    top: 3rem;
    right: 1.8rem;
  }
  
  @include breakpoint(1024) {
    top: 4rem;
    right: 1.5rem;
  }
  
  @include breakpoint(1280) {
    top: 5rem;
    right: 0;
  }
}

.plaid-consumer-img3 {
  
  @include breakpoint(medium only) {
    position: relative;
    padding-top: 5rem;
  }
  
  @include breakpoint(small only) {
    padding-top: 2rem;
  }
  
  @include breakpoint(800) {
    padding-top: 3rem;
  }
  
  @include breakpoint(1024) {
    position: absolute;
    width: 50rem;
    padding-left: 5rem;
    padding-top: 8rem;
  }
  
  @include breakpoint(1280) {
  }
}

.plaid-consumer-img4 {
  padding-bottom: 2rem;

  @include breakpoint(small only) {
    padding-bottom: 0rem;
    width: 49%;
    padding-right: 0.5rem;
    padding-top: 1rem;
  }

  @include breakpoint(medium only) {
    padding-bottom: 0rem;
    width: 49.5%;
    padding-right: 0.5rem;
    padding-top: 2rem;
  }
  
  @include breakpoint(1024) {
    width: 30rem;
  }
}

.plaid-consumer-img5 {
  
  @include breakpoint(small only) {
    width: 49%;
    padding-left: 0.5rem;
    padding-top: 1rem;
  }

  @include breakpoint(medium only) {
    width: 49.5%;
    padding-left: 0.5rem;
    padding-top: 2rem;
  }
  
  @include breakpoint(1024) {
    width: 30rem;
  }
}

.plaid-consumer-img7 {
  
  @include breakpoint(medium only) {
    position: relative;
    padding-top: 5rem;
  }
  
  @include breakpoint(small only) {
    padding-top: 1rem;
  }
  
  @include breakpoint(800) {
    padding-top: 3rem;
  }
  
  @include breakpoint(1024) {
    position: absolute;
    width: 50rem;
    padding-left: 5rem;
    padding-top: 0rem;
  }
}

.plaid-consumer-img8 {
  
}

.plaid-consumer-img9 {
  padding-top: 6rem;
  padding-bottom: 6rem;

  @include breakpoint(small only) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.plaid-consumer-img10 {
  padding-bottom: 6rem;

  @include breakpoint(small only) {
    padding-bottom: 2rem;
  }
}

.plaid-consumer-img12 {
  padding-bottom: 2rem;

  @include breakpoint(small only) {
    padding-bottom: 1rem;
  }
}

.plaid-consumer-img13 {
  padding-bottom: 2rem;

  @include breakpoint(small only) {
    padding-bottom: 1rem;
  }
}

.plaid-consumer-video-bg-01 {
  display: block;
  background-color: #fdfdfd;
  text-align: center;
  width: 100%;
  max-height: 570px;
  
  @include breakpoint(small only) {
    height: 46vw;
    padding-top: 1rem;
  }

  @include breakpoint(medium only) {
    height: 46vw;
    padding-top: 0rem;
  }
  
  @include breakpoint(800) {
    height: 47vw;
    padding-top: 1rem;
  }
  
  @include breakpoint(1024) {
    height: 47.3vw;
    padding-top: 3.8rem;
  }
}

.plaid-consumer-img14 {
  padding-bottom: 0rem;
  height: auto;

  @include breakpoint(small only) {
    padding-top: 0rem;
    width: 80%;
  }

  @include breakpoint(medium only) {
    padding-top: 1rem;
    width: 80%;
  }

  @include breakpoint(800) {
    
  } 

  @include breakpoint(1024) {
    padding-top: 1rem;
    width: 70%;
  } 
}

.plaid-consumer-img15 {
  padding-bottom: 2rem;

  @include breakpoint(small only) {
    padding-bottom: 1rem;
  }
}

.plaid-consumer-section-partial-top {
  padding: 8rem 0 0 0;
  
  @include breakpoint(medium only){
    padding: 5rem 0 0 0;
  }
  
  @include breakpoint(small only) {
    padding: 3rem 0 0 0;
  }
}

.plaid-consumer-video-bg-02 {
  display: block;
  width: 100%;
  max-height: 570px;
}

.plaid-consumer-img16 {
  height: auto;
  max-width: 570px;
  position: relative;

  @include breakpoint(small only) {
    width: 46vw;
  }

  @include breakpoint(medium only) {
    width: 46vw;
  }

  @include breakpoint(800) {
    width: 47vw;
  } 

  @include breakpoint(1024) {
    width: 47.3vw;
  } 
}