// ZURB Colors
$zurb-earth: #B8D500;
$zurb-neptune: #21AEC0;
$zurb-pluto: #B8D500;
$zurb-sun: #FF5B00;
$zurb-mars: #B8D500;
$zurb-space: #2A2E31;
$zurb-helio: #5D45BC;


// All the Grays
$true-white: #fff;
$gray-100: #FBFBFB;
$gray-200: #F8F8F8;
$gray-300: #EFEFEF;
$gray-400: #E5E5E5;
$gray-500: #BEBFC1;
$gray-600: #848589;
$gray-700: #525455;
$gray-800: #3E4042;
$gray-900: #2A2E31;
$true-black: #000;

// Setting the primary & secondary colors
$primary-color: $zurb-earth;
$secondary-color: $gray-600;

// Case Studies
$paysa-color: #313243;
$iqtell-color: #00a3e8;
$tcl-color: #1D87EC;
$wtsbooks-color: #EE9223;
$teal-1: #1D87EC;
$teal-2: #28DBCD;
$tcl-teal-1: #1D87EC;
$tcl-teal-2: #28DBCD;
$wts-1: #FFB500;
$wts-2: #EE9223;

// Wired Colors
$life-services: #1276BD;
$wired-rcs-color: #087A94;
$wired-family-color: #8B3470;
$wired-raft-color: #1A7E56;
$wired-pie-ranch-color: #B95623;
$wired-dst-color: #7F317A;
$wired-lsa-color: #F96F50;
$wired-rebuilding-color: #7BC043;
$wired-sacred-heart-color: #B32A30;
$wired-second-harvest-color: #e8ac0a;

// Cow tag for new cows
$cow-tag: #FF8A2E;
