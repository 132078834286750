.foundation-section-desc {
  padding-top: 7rem;
  
  @include breakpoint(medium only) {
    padding-top: 5rem;
  }
  
  @include breakpoint(small only) {
    padding-top: 4rem;
  }
}

.foundation-section-desc1 {
  
  @include breakpoint(medium only) {
    padding-left: 1.9375rem;
  }
  
  @include breakpoint(800) {
    padding-left: 2rem;
    padding-right: 1rem;
  }
  
  @include breakpoint(1024) {
    padding-left: 2rem;
    padding-right: 4rem;
  }
  
  @include breakpoint(1300) {
    padding-left: 0;
    padding-right: 5rem;
  }
}

.foundation-section-desc2 {
  padding-top: 9rem;
  
  @include breakpoint(medium only) {
    padding-top: 1rem;
  }
  
  @include breakpoint(small only) {
    padding-top: 2rem;
  }
}

.foundation-section-desc3 {
  
  @include breakpoint(small only) {
    padding-top: 1rem;
  }
}

.foundation-img-wrapper {
  position: relative;
  
  @include breakpoint(small only) {
    
  }
}

.foundation-img1 {
  width: 80%;
  
  @include breakpoint(small only) {
    width: 83%;
  }
  
  @include breakpoint(medium only) {
    width: 83%;
    padding-left: 1rem;
  }
  
  @include breakpoint(800) {
    padding-left: 1rem;
  }
  
  @include breakpoint(1024) {
    padding-left: 2rem;
  }
  
  @include breakpoint(1280) {
    padding-left: 1rem;
  }
}

.foundation-img2 {
  width: 23%;
  position: absolute;

  @include breakpoint(medium only) {
    top: 6rem;
    right: 1.8rem;
  }
  
  @include breakpoint(small only) {
    top: 2.5rem;
    right: 1.25rem;
  }
  
  @include breakpoint(800) {
    top: 8rem;
    right: 1.8rem;
  }
  
  @include breakpoint(1024) {
    top: 10rem;
    right: 1.8rem;
  }
  
  @include breakpoint(1280) {
    top: 12rem;
    right: 0;
  }
}

.foundation-img3 {
  
}

.foundation-img4 {
  position: absolute;
  top: -28rem;
  width: 50%;
  right: 10rem;
  
  @include breakpoint(medium only) {
    top: -250px;
    left: 5rem;
    width: 80%;
  }
  
  @include breakpoint(small only) {
    top: -125px;
    right: 3rem;
  }
}

.foundation-img5 {
  padding-top: 10rem;
  
  @include breakpoint(medium only) {
    padding-top: 0;
  }
  
  @include breakpoint(small only) {
    padding-top: 7rem;
  }
}

.foundation-img6 {
  
  @include breakpoint(medium only) {
    padding-top: 7.5rem;
  }
}

.foundation-img7 {
  padding-top: 4rem;
  
  @include breakpoint(medium only) {
    padding-top: 3rem;
  }
  
  @include breakpoint(small only) {
    padding-top: 3rem;
  }
}

.foundation-img8 {
  padding-top: 10rem;
  
  @include breakpoint(medium only) {
    padding-top: 15rem;
  }
  
  @include breakpoint(small only) {
    padding-top: 3rem;
  }
}

.foundation-img9 {
  padding-top: 4rem;
  
  @include breakpoint(medium only) {
    padding-top: 3rem;
  }
  
  @include breakpoint(small only) {
    padding-top: 3rem;
  }
}

.foundation-img10 {
  padding-top: 20.9rem;
  
  @include breakpoint(medium only) {
    padding-top: 3rem;
  }
  
  @include breakpoint(small only) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.foundation-image-section {
  padding-top: 4rem;
  
  @include breakpoint(small only) {
    padding-top: 2rem;
  }
}

.foundation-website-img {
  background-color: $gray-200;
}