html {
  font-size: 1rem;
}

body {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  line-height: 1.45;
  font-size: 1.25rem;
  color: $gray-700;
  
  @include breakpoint(small only){
    font-size: 1rem;  
  }
}

ul {
  margin-left: 0;
  
  li {
    font-size: 1.25rem;
    list-style: none;
  }
}

h3 {
  font-family: 'Karla', sans-serif;;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3em;
  margin: 0;

  @include breakpoint(medium down) {
    font-size: 2rem;
    line-height: 1.25em;
  }
  
  @include breakpoint(small down) {
    font-size: 1.45rem;
    line-height: 1.3em;
  }
}

h4 {
  font-family: 'Karla', sans-serif;;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2em;
  color: $true-black;
  margin: 0;

  @include breakpoint(small only) {
    font-size: 1.1rem;
    line-height: 1.5em;
  }
}

h5 {
  font-family: 'Karla', sans-serif;;
  font-size: 1.28rem;
  letter-spacing: -0.25px;
  font-weight: 800;
  line-height: 1.4em;
  color: $true-black;
  margin: 0;

  @include breakpoint(small only) {
    font-size: 1rem;
  }
}

h6 {
  font-family: 'Karla', sans-serif;;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5em;
  margin: 0;

  @include breakpoint(small only) {
    font-size: 0.85rem;
  }
}

a {
  color: $gray-800;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover,
  &:focus {
    color: $gray-900;
    transition: color 0.3s ease;
  }
}
