@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #1468a0; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

html {
  font-size: 1rem; }

body {
  font-family: 'Hind', sans-serif;
  font-weight: 400;
  line-height: 1.45;
  font-size: 1.25rem;
  color: #525455; }
  @media screen and (max-width: 39.9375em) {
    body {
      font-size: 1rem; } }

ul {
  margin-left: 0; }
  ul li {
    font-size: 1.25rem;
    list-style: none; }

h3 {
  font-family: 'Karla', sans-serif;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.3em;
  margin: 0; }
  @media screen and (max-width: 63.9375em) {
    h3 {
      font-size: 2rem;
      line-height: 1.25em; } }
  @media screen and (max-width: 39.9375em) {
    h3 {
      font-size: 1.45rem;
      line-height: 1.3em; } }

h4 {
  font-family: 'Karla', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2em;
  color: #000;
  margin: 0; }
  @media screen and (max-width: 39.9375em) {
    h4 {
      font-size: 1.1rem;
      line-height: 1.5em; } }

h5 {
  font-family: 'Karla', sans-serif;
  font-size: 1.28rem;
  letter-spacing: -0.25px;
  font-weight: 800;
  line-height: 1.4em;
  color: #000;
  margin: 0; }
  @media screen and (max-width: 39.9375em) {
    h5 {
      font-size: 1rem; } }

h6 {
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5em;
  margin: 0; }
  @media screen and (max-width: 39.9375em) {
    h6 {
      font-size: 0.85rem; } }

a {
  color: #3E4042;
  text-decoration: none;
  transition: color 0.3s ease; }
  a:hover, a:focus {
    color: #2A2E31;
    transition: color 0.3s ease; }

@media screen and (max-width: 39.9375em) {
  .header-profile-wrapper {
    padding: 0rem 1.5rem; } }

@media screen and (max-width: 39.9375em) {
  .header-profile-wrapper {
    padding: 0 1rem; } }

.header-profile-pic2 {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 5; }

.header-profile-info2 {
  position: absolute;
  padding-left: 3.5rem;
  margin-bottom: 0.4rem; }
  @media screen and (max-width: 39.9375em) {
    .header-profile-info2 {
      padding-left: 3.1rem; } }

.header-profile-name2 {
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0.45rem 0 0 0;
  color: #2A2E31;
  letter-spacing: 0px; }

.header-profile-profession2 {
  font-weight: 400;
  color: #BEBFC1; }

.header-contact-info {
  margin-bottom: 0.4rem; }

.header-contact-info-item {
  color: #BEBFC1;
  font-family: 'Karla', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  padding: 0.45rem 0 0 0;
  letter-spacing: -0.2px;
  text-align: right; }

.header-contact-link2 {
  color: #BEBFC1; }

.header-profile-pic {
  position: absolute;
  width: 50px;
  height: 50px; }

.header-profile-info {
  padding-left: 4.1rem; }
  @media screen and (max-width: 39.9375em) {
    .header-profile-info {
      margin-bottom: 0.5rem; } }

.header-profile-name {
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0.25rem 0 0 0;
  color: #2A2E31;
  letter-spacing: -0.5px; }

.header-profile-profession {
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  color: #BEBFC1;
  padding: 0;
  letter-spacing: -0.2px; }

body {
  overflow-x: hidden; }
  @media screen and (max-width: 39.9375em) {
    body {
      padding-bottom: 2rem; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    body {
      padding-bottom: 3rem; } }
  @media screen and (min-width: 64em) {
    body {
      padding-bottom: 4rem; } }
  @media screen and (min-width: 75em) {
    body {
      padding-bottom: 5rem; } }

.particle-header {
  position: relative; }
  @media screen and (max-width: 39.9375em) {
    .particle-header {
      padding-top: 5rem; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .particle-header {
      padding-top: 6rem; } }
  @media screen and (min-width: 64em) {
    .particle-header {
      padding-top: 8rem; } }
  @media screen and (min-width: 75em) {
    .particle-header {
      padding-top: 10rem; } }

.particles-stage {
  height: 600px;
  width: 750px;
  right: 0;
  top: 0;
  opacity: 0;
  z-index: -9999;
  position: absolute;
  transition: opacity 2s ease;
  transition-delay: 1s; }
  .particles-stage.is-loaded {
    opacity: 1; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .particles-stage {
      width: 450px; } }
  @media screen and (max-width: 39.9375em) {
    .particles-stage {
      width: 250px; } }

.work-details-header {
  margin-top: 16rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .work-details-header {
      margin-top: 11rem;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .work-details-header {
      margin-top: 7rem;
      padding-left: 0rem;
      margin-bottom: 3rem; } }

.title {
  color: #2A2E31;
  font-weight: 600;
  letter-spacing: -0.5px; }
  @media screen and (max-width: 39.9375em) {
    .title {
      font-size: 1.5rem; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .title {
      font-size: 1.5rem; } }
  @media screen and (min-width: 50em) {
    .title {
      font-size: 1.7rem; } }
  @media screen and (min-width: 64.0625em) {
    .title {
      font-size: 2rem; } }

.subtitle {
  color: #BEBFC1;
  font-weight: 400;
  margin-bottom: 5rem;
  letter-spacing: -0.5px;
  font-size: 2rem;
  line-height: 1.2; }
  @media screen and (max-width: 39.9375em) {
    .subtitle {
      font-size: 1.5rem;
      margin-bottom: 0; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .subtitle {
      font-size: 1.5rem; } }
  @media screen and (min-width: 50em) {
    .subtitle {
      font-size: 1.7rem; } }
  @media screen and (min-width: 64.0625em) {
    .subtitle {
      font-size: 2rem; } }

.hero {
  margin-bottom: 1rem; }
  .hero.plaid-consumer {
    background: -webkit-linear-gradient(left, #111111, #111111 80%, transparent 80%, transparent 100%);
    background: -o-linear-gradient(left, #111111, #111111 80%, transparent 80%, transparent 100%);
    background: linear-gradient(to right, #111111, #111111 80%, transparent 80%, transparent 100%); }
    @media screen and (max-width: 39.9375em) {
      .hero.plaid-consumer {
        background: -webkit-linear-gradient(bottom, #111111, #111111 80%, transparent 80%, transparent 100%);
        background: -o-linear-gradient(bottom, #111111, #111111 80%, transparent 80%, transparent 100%);
        background: linear-gradient(to top, #111111, #111111 80%, transparent 80%, transparent 100%); } }
  .hero.wtsbooks {
    background: -webkit-linear-gradient(left, #ffa514, #ffa514 80%, transparent 80%, transparent 100%);
    background: -o-linear-gradient(left, #ffa514, #ffa514 80%, transparent 80%, transparent 100%);
    background: linear-gradient(to right, #ffa514, #ffa514 80%, transparent 80%, transparent 100%); }
    @media screen and (max-width: 39.9375em) {
      .hero.wtsbooks {
        background: -webkit-linear-gradient(bottom, #ffa514, #ffa514 80%, transparent 80%, transparent 100%);
        background: -o-linear-gradient(bottom, #ffa514, #ffa514 80%, transparent 80%, transparent 100%);
        background: linear-gradient(to top, #ffa514, #ffa514 80%, transparent 80%, transparent 100%); } }
  .hero.foundation {
    background: -webkit-linear-gradient(left, #1c292e, #1c292e 80%, transparent 80%, transparent 100%);
    background: -o-linear-gradient(left, #1c292e, #1c292e 80%, transparent 80%, transparent 100%);
    background: linear-gradient(to right, #1c292e, #1c292e 80%, transparent 80%, transparent 100%); }
    @media screen and (max-width: 39.9375em) {
      .hero.foundation {
        background: -webkit-linear-gradient(bottom, #1c292e, #1c292e 80%, transparent 80%, transparent 100%);
        background: -o-linear-gradient(bottom, #1c292e, #1c292e 80%, transparent 80%, transparent 100%);
        background: linear-gradient(to top, #1c292e, #1c292e 80%, transparent 80%, transparent 100%); } }
  .hero.samsung {
    background: -webkit-linear-gradient(left, #1e74b1, #00bbc0 80%, transparent 80%, transparent 100%);
    background: -o-linear-gradient(left, #1e74b1, #00bbc0 80%, transparent 80%, transparent 100%);
    background: linear-gradient(to right, #1e74b1, #00bbc0 80%, transparent 80%, transparent 100%); }
    @media screen and (max-width: 39.9375em) {
      .hero.samsung {
        background: -webkit-linear-gradient(bottom, #1e74b1, #00bbc0 80%, transparent 80%, transparent 100%);
        background: -o-linear-gradient(bottom, #1e74b1, #00bbc0 80%, transparent 80%, transparent 100%);
        background: linear-gradient(to top, #1e74b1, #00bbc0 80%, transparent 80%, transparent 100%); } }
  .hero.zurb {
    background: -webkit-linear-gradient(left, #b8d500, #b8d500 80%, transparent 80%, transparent 100%);
    background: -o-linear-gradient(left, #b8d500, #b8d500 80%, transparent 80%, transparent 100%);
    background: linear-gradient(to right, #b8d500, #b8d500 80%, transparent 80%, transparent 100%); }
    @media screen and (max-width: 39.9375em) {
      .hero.zurb {
        background: -webkit-linear-gradient(bottom, #b8d500, #b8d500 80%, transparent 80%, transparent 100%);
        background: -o-linear-gradient(bottom, #b8d500, #b8d500 80%, transparent 80%, transparent 100%);
        background: linear-gradient(to top, #b8d500, #b8d500 80%, transparent 80%, transparent 100%); } }
  .hero.myvotetoday {
    background: -webkit-linear-gradient(left, #db3231, #db3231 80%, transparent 80%, transparent 100%);
    background: -o-linear-gradient(left, #db3231, #db3231 80%, transparent 80%, transparent 100%);
    background: linear-gradient(to right, #db3231, #db3231 80%, transparent 80%, transparent 100%); }
    @media screen and (max-width: 39.9375em) {
      .hero.myvotetoday {
        background: -webkit-linear-gradient(bottom, #db3231, #db3231 80%, transparent 80%, transparent 100%);
        background: -o-linear-gradient(bottom, #db3231, #db3231 80%, transparent 80%, transparent 100%);
        background: linear-gradient(to top, #db3231, #db3231 80%, transparent 80%, transparent 100%); } }

.hero-text-wrapper {
  position: relative;
  padding: 0; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .hero-text-wrapper {
      height: 320px;
      padding: 0; } }
  @media screen and (max-width: 39.9375em) {
    .hero-text-wrapper {
      height: auto; } }
  @media screen and (min-width: 40em) {
    .hero-text-wrapper {
      height: 340px; } }
  @media screen and (min-width: 50em) {
    .hero-text-wrapper {
      height: 360px; } }
  @media screen and (min-width: 64.0625em) {
    .hero-text-wrapper {
      height: 420px; } }
  @media screen and (min-width: 71.875em) {
    .hero-text-wrapper {
      height: 460px; } }

.hero-text {
  position: absolute;
  bottom: 3rem;
  font-size: 2rem;
  line-height: 1.3;
  color: #fefefe;
  padding-right: 6rem;
  padding-left: 1rem; }
  .hero-text.plaid-consumer {
    color: #777777; }
  .hero-text.zurb {
    color: #f2f8cf; }
  .hero-text.samsung {
    color: #9de6f7; }
  .hero-text.foundation {
    color: #65787e; }
  .hero-text.wtsbooks {
    color: #ffeac9; }
  .hero-text.myvotetoday {
    color: #f6c9c9; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .hero-text {
      font-size: 1.5rem;
      bottom: 2rem;
      padding: 0 2rem 0 1rem; } }
  @media screen and (min-width: 50em) {
    .hero-text {
      font-size: 1.7rem; } }
  @media screen and (min-width: 64.0625em) {
    .hero-text {
      font-size: 2rem; } }

@media screen and (max-width: 39.9375em) {
  .hero-text-mobile {
    color: #fefefe;
    font-size: 1.5rem;
    line-height: 1.3;
    bottom: auto;
    padding: 0 0.625rem 2rem;
    position: inherit; } }

.hero-text-mobile.plaid-consumer {
  color: #777777; }

.hero-text-mobile.zurb {
  color: #f2f8cf; }

.hero-text-mobile.samsung {
  color: #9de6f7; }

.hero-text-mobile.foundation {
  color: #65787e; }

.hero-text-mobile.wtsbooks {
  color: #ffeac9; }

.hero-text-mobile.myvotetoday {
  color: #f6c9c9; }

.hero-text .hero-link {
  color: #fff;
  opacity: 1.0;
  transition: opacity 0.3s ease; }

.hero-text-mobile .hero-link {
  color: #fff;
  opacity: 1.0;
  transition: opacity 0.3s ease; }

.hero-link:hover,
.hero-link:focus {
  opacity: 1.0;
  transition: opacity 0.3s ease; }

.hero-img {
  position: absolute;
  top: -11rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .hero-img {
      padding-right: 2rem;
      top: -9rem; } }
  @media screen and (max-width: 39.9375em) {
    .hero-img {
      position: inherit;
      padding: 0 0.1rem 2.5rem; } }
  @media screen and (min-width: 50em) {
    .hero-img {
      padding-right: 2rem; } }

@media screen and (max-width: 39.9375em) {
  .intro-meta {
    padding-left: 0.625rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .intro-meta {
    padding-left: 0.9375rem; } }

@media screen and (min-width: 50em) {
  .intro-meta {
    padding-left: 0.9375rem; } }

@media screen and (min-width: 64em) {
  .intro-meta {
    padding-left: 0.9375rem; } }

@media screen and (min-width: 76.25em) {
  .intro-meta {
    padding-left: 0; } }

.section {
  padding: 8rem 1rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .section {
      padding: 6rem 1rem; } }
  @media screen and (max-width: 39.9375em) {
    .section {
      padding: 3rem 0; } }

.section-partial {
  padding: 0 0 8rem 0; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .section-partial {
      padding: 0 0 4rem 0; } }
  @media screen and (max-width: 39.9375em) {
    .section-partial {
      padding: 0 0 3rem 0; } }

.section-title {
  margin-bottom: 1rem; }
  @media screen and (max-width: 39.9375em) {
    .section-title {
      padding: 0 0.625rem; } }
  .section-title.additional-title {
    padding-top: 2rem; }

@media screen and (max-width: 39.9375em) {
  .intro-meta-list {
    padding: 0 0.625rem; } }

@media screen and (max-width: 39.9375em) {
  .intro-meta-list li {
    font-size: 1rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .intro-text {
    padding-right: 1rem; } }

@media screen and (max-width: 39.9375em) {
  .intro-text {
    margin-top: 1rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem; } }

.img-wrapper {
  position: relative; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .img-wrapper {
      padding: 0 1.25rem; } }
  @media screen and (max-width: 39.9375em) {
    .img-wrapper {
      padding: 0 1.25rem; } }

@media screen and (max-width: 39.9375em) {
  .body-text {
    padding: 0 0.625rem; } }

.bg-color.gray-partial {
  background: -webkit-linear-gradient(bottom, #F8F8F8, #F8F8F8 85%, transparent 85%, transparent 100%);
  background: -o-linear-gradient(bottom, #F8F8F8, #F8F8F8 85%, transparent 85%, transparent 100%);
  background: linear-gradient(to top, #F8F8F8, #F8F8F8 85%, transparent 85%, transparent 100%); }

@media screen and (max-width: 39.9375em) {
  .bg-color.myvotetoday-full {
    background: -webkit-linear-gradient(bottom, #F8F8F8, #F8F8F8 100%, transparent 100%, transparent 100%); } }

@media screen and (min-width: 40em) {
  .bg-color.myvotetoday-full {
    background: -webkit-linear-gradient(bottom, #F8F8F8, #F8F8F8 100%, transparent 100%, transparent 100%); } }

@media screen and (min-width: 50em) {
  .bg-color.myvotetoday-full {
    background: -webkit-linear-gradient(bottom, #F8F8F8, #F8F8F8 85%, transparent 85%, transparent 100%); } }

.bg-color.gray-partial-top {
  background: -webkit-linear-gradient(top, #F8F8F8, #F8F8F8 60%, transparent 60%, transparent 100%);
  background: -o-linear-gradient(top, #F8F8F8, #F8F8F8 60%, transparent 60%, transparent 100%);
  background: linear-gradient(to bottom, #F8F8F8, #F8F8F8 60%, transparent 60%, transparent 100%); }

.bg-color.gray-full {
  background-color: #F8F8F8; }

.bg-color.samsung-gray-partial {
  background: -webkit-linear-gradient(bottom, #F8F8F8, #F8F8F8 50%, transparent 50%, transparent 100%);
  background: -o-linear-gradient(bottom, #F8F8F8, #F8F8F8 50%, transparent 50%, transparent 100%);
  background: linear-gradient(to top, #F8F8F8, #F8F8F8 50%, transparent 50%, transparent 100%); }

.bg-color.zurb-gray-partial {
  background: -webkit-linear-gradient(top, #F8F8F8, #F8F8F8 30%, transparent 30%, transparent 100%);
  background: -o-linear-gradient(top, #F8F8F8, #F8F8F8 30%, transparent 30%, transparent 100%);
  background: linear-gradient(to bottom, #F8F8F8, #F8F8F8 30%, transparent 30%, transparent 100%); }

.bg-color.plaid-consumer-gray-partial {
  background: -webkit-linear-gradient(bottom, #F8F8F8, #F8F8F8 50%, transparent 50%, transparent 100%);
  background: -o-linear-gradient(bottom, #F8F8F8, #F8F8F8 50%, transparent 50%, transparent 100%);
  background: linear-gradient(to top, #F8F8F8, #F8F8F8 50%, transparent 50%, transparent 100%); }

.callout-section {
  padding: 6rem 0; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .callout-section {
      padding: 4rem 1rem; } }
  @media screen and (max-width: 39.9375em) {
    .callout-section {
      padding: 2rem 0; } }

.morework-header {
  padding-top: 12rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .morework-header {
      padding-top: 8rem; } }
  @media screen and (max-width: 39.9375em) {
    .morework-header {
      padding-top: 2rem; } }

.wtsbooks-img1 {
  width: 80%; }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-img1 {
      width: 83%; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-img1 {
      width: 83%;
      padding-left: 1rem; } }
  @media screen and (min-width: 50em) {
    .wtsbooks-img1 {
      padding-left: 1rem; } }
  @media screen and (min-width: 64em) {
    .wtsbooks-img1 {
      padding-left: 2rem; } }
  @media screen and (min-width: 80em) {
    .wtsbooks-img1 {
      padding-left: 1rem; } }

.wtsbooks-img2 {
  width: 23%;
  position: absolute; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-img2 {
      top: 6rem;
      right: 1.8rem; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-img2 {
      top: 3rem;
      right: 1.25rem; } }
  @media screen and (min-width: 50em) {
    .wtsbooks-img2 {
      top: 8rem;
      right: 1.8rem; } }
  @media screen and (min-width: 64em) {
    .wtsbooks-img2 {
      top: 10rem;
      right: 1.8rem; } }
  @media screen and (min-width: 80em) {
    .wtsbooks-img2 {
      top: 12rem;
      right: 0; } }

.wtsbooks-img3 {
  width: 85%; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-img3 {
      width: 90%;
      padding: 0 1.25rem 0; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-img3 {
      width: 100%;
      padding: 3rem 0.625rem 0; } }
  @media screen and (min-width: 62.5em) {
    .wtsbooks-img3 {
      padding: 0 1rem 0; } }
  @media screen and (min-width: 75em) {
    .wtsbooks-img3 {
      padding: 1rem 0 0; } }

@media screen and (max-width: 39.9375em) {
  .wtsbooks-section-desc {
    padding-top: 0; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .wtsbooks-section-desc {
    padding-top: 0rem;
    padding-right: 2rem; } }

@media screen and (min-width: 43.75em) {
  .wtsbooks-section-desc {
    padding-top: 4rem;
    padding-right: 2rem; } }

@media screen and (min-width: 62.5em) {
  .wtsbooks-section-desc {
    padding-top: 8rem;
    padding-right: 2rem; } }

@media screen and (min-width: 75em) {
  .wtsbooks-section-desc {
    padding-top: 13rem;
    padding-right: 0; } }

.wtsbooks-section-desc3 {
  padding-top: 16rem;
  padding-right: 5rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-section-desc3 {
      padding-top: 12rem;
      padding-right: 1.25rem;
      padding-bottom: 2rem; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-section-desc3 {
      padding-top: 1rem;
      padding-right: 1.25rem;
      padding-bottom: 2rem; } }

.wtsbooks-img4 {
  margin-bottom: 5rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-img4 {
      margin-top: 1rem;
      margin-bottom: 3rem; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-img4 {
      margin-bottom: 3rem; } }
  @media screen and (min-width: 62.5em) {
    .wtsbooks-img4 {
      margin-top: 0;
      margin-bottom: 3rem;
      padding-right: 0rem; } }

.wtsbooks-section-desc2 {
  padding-top: 30rem;
  padding-right: 5rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-section-desc2 {
      padding-top: 16rem;
      padding-right: 1.25rem;
      padding-bottom: 3rem; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-section-desc2 {
      padding-top: 1rem;
      padding-right: 1.25rem;
      padding-bottom: 2rem; } }

.wtsbooks-img5 {
  position: absolute;
  width: 55%; }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-img5 {
      position: relative;
      width: 100%;
      padding: 0 0.625rem 1rem 0.625rem; } }

.wtsbooks-img7 {
  position: absolute;
  width: 95%;
  top: 10rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-img7 {
      top: 9rem; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-img7 {
      position: relative;
      top: 0;
      width: 100%;
      padding-top: 5rem; } }

.wtsbooks-img8 {
  position: absolute;
  width: 95%;
  top: -6rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-img8 {
      position: relative;
      top: 0;
      width: 100%;
      margin-bottom: 1rem; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-img8 {
      position: relative;
      top: 0;
      width: 100%; } }

.wtsbooks-img9 {
  position: absolute;
  width: 95%; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-img9 {
      position: relative;
      top: 0;
      width: 100%; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-img9 {
      position: relative;
      top: 0;
      width: 100%; } }
  @media screen and (min-width: 64em) {
    .wtsbooks-img9 {
      top: 26rem; } }
  @media screen and (min-width: 66.25em) {
    .wtsbooks-img9 {
      top: 28rem; } }
  @media screen and (min-width: 68.75em) {
    .wtsbooks-img9 {
      top: 30rem; } }
  @media screen and (min-width: 75em) {
    .wtsbooks-img9 {
      top: 32rem; } }

.wtsbooks-section-desc4 {
  padding-top: 9rem;
  padding-right: 5rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-section-desc4 {
      padding-top: 1rem; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-section-desc4 {
      padding-top: 1rem;
      padding-right: 1.25rem; } }

.wtsbooks-logo {
  background-color: #F8F8F8;
  padding: 13rem 0; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-logo {
      padding: 9rem 0; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-logo {
      padding: 4rem 0; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .wtsbooks-img10 {
    width: 60%; } }

@media screen and (max-width: 39.9375em) {
  .wtsbooks-img10 {
    width: 75%; } }

.wtsbooks-logo-light {
  position: relative;
  padding-left: 2rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-logo-light {
      padding: 0 1.25rem; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-logo-light {
      padding: 0 1.25rem; } }

.wtsbooks-logo-dark {
  padding-left: 0;
  padding-top: 15rem; }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-logo-dark {
      padding: 0 1.25rem; } }

.wtsbook-logo-img1 {
  width: 100%; }
  @media screen and (max-width: 39.9375em) {
    .wtsbook-logo-img1 {
      padding-top: 1.5rem; } }

.wtsbook-logo-img2 {
  width: 100%;
  position: absolute;
  top: -3rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbook-logo-img2 {
      top: -2rem; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbook-logo-img2 {
      position: relative;
      top: 0;
      padding-top: 1.5rem; } }

.wtsbooks-img-wrapper1 {
  position: relative; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-img-wrapper1 {
      padding: 0 1.25rem; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-img-wrapper1 {
      padding-left: 1.25rem; } }

.wtsbooks-img-wrapper2 {
  position: relative; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .wtsbooks-img-wrapper2 {
      padding: 0 1.25rem; } }
  @media screen and (max-width: 39.9375em) {
    .wtsbooks-img-wrapper2 {
      padding-right: 1.25rem; } }

.foundation-section-desc {
  padding-top: 7rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .foundation-section-desc {
      padding-top: 5rem; } }
  @media screen and (max-width: 39.9375em) {
    .foundation-section-desc {
      padding-top: 4rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .foundation-section-desc1 {
    padding-left: 1.9375rem; } }

@media screen and (min-width: 50em) {
  .foundation-section-desc1 {
    padding-left: 2rem;
    padding-right: 1rem; } }

@media screen and (min-width: 64em) {
  .foundation-section-desc1 {
    padding-left: 2rem;
    padding-right: 4rem; } }

@media screen and (min-width: 81.25em) {
  .foundation-section-desc1 {
    padding-left: 0;
    padding-right: 5rem; } }

.foundation-section-desc2 {
  padding-top: 9rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .foundation-section-desc2 {
      padding-top: 1rem; } }
  @media screen and (max-width: 39.9375em) {
    .foundation-section-desc2 {
      padding-top: 2rem; } }

@media screen and (max-width: 39.9375em) {
  .foundation-section-desc3 {
    padding-top: 1rem; } }

.foundation-img-wrapper {
  position: relative; }

.foundation-img1 {
  width: 80%; }
  @media screen and (max-width: 39.9375em) {
    .foundation-img1 {
      width: 83%; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .foundation-img1 {
      width: 83%;
      padding-left: 1rem; } }
  @media screen and (min-width: 50em) {
    .foundation-img1 {
      padding-left: 1rem; } }
  @media screen and (min-width: 64em) {
    .foundation-img1 {
      padding-left: 2rem; } }
  @media screen and (min-width: 80em) {
    .foundation-img1 {
      padding-left: 1rem; } }

.foundation-img2 {
  width: 23%;
  position: absolute; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .foundation-img2 {
      top: 6rem;
      right: 1.8rem; } }
  @media screen and (max-width: 39.9375em) {
    .foundation-img2 {
      top: 2.5rem;
      right: 1.25rem; } }
  @media screen and (min-width: 50em) {
    .foundation-img2 {
      top: 8rem;
      right: 1.8rem; } }
  @media screen and (min-width: 64em) {
    .foundation-img2 {
      top: 10rem;
      right: 1.8rem; } }
  @media screen and (min-width: 80em) {
    .foundation-img2 {
      top: 12rem;
      right: 0; } }

.foundation-img4 {
  position: absolute;
  top: -28rem;
  width: 50%;
  right: 10rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .foundation-img4 {
      top: -250px;
      left: 5rem;
      width: 80%; } }
  @media screen and (max-width: 39.9375em) {
    .foundation-img4 {
      top: -125px;
      right: 3rem; } }

.foundation-img5 {
  padding-top: 10rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .foundation-img5 {
      padding-top: 0; } }
  @media screen and (max-width: 39.9375em) {
    .foundation-img5 {
      padding-top: 7rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .foundation-img6 {
    padding-top: 7.5rem; } }

.foundation-img7 {
  padding-top: 4rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .foundation-img7 {
      padding-top: 3rem; } }
  @media screen and (max-width: 39.9375em) {
    .foundation-img7 {
      padding-top: 3rem; } }

.foundation-img8 {
  padding-top: 10rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .foundation-img8 {
      padding-top: 15rem; } }
  @media screen and (max-width: 39.9375em) {
    .foundation-img8 {
      padding-top: 3rem; } }

.foundation-img9 {
  padding-top: 4rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .foundation-img9 {
      padding-top: 3rem; } }
  @media screen and (max-width: 39.9375em) {
    .foundation-img9 {
      padding-top: 3rem; } }

.foundation-img10 {
  padding-top: 20.9rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .foundation-img10 {
      padding-top: 3rem; } }
  @media screen and (max-width: 39.9375em) {
    .foundation-img10 {
      padding-top: 3rem;
      padding-bottom: 3rem; } }

.foundation-image-section {
  padding-top: 4rem; }
  @media screen and (max-width: 39.9375em) {
    .foundation-image-section {
      padding-top: 2rem; } }

.foundation-website-img {
  background-color: #F8F8F8; }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .samsung-desc {
    padding-right: 2rem; } }

@media screen and (max-width: 39.9375em) {
  .samsung-desc {
    padding-top: 1rem; } }

@media screen and (max-width: 39.9375em) {
  .samsung-img1 {
    padding: 0 0.625rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .samsung-img1 {
    padding: 0 1rem; } }

@media screen and (min-width: 64em) {
  .samsung-img1 {
    padding: 0 1rem; } }

@media screen and (min-width: 75em) {
  .samsung-img1 {
    padding: 0; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .samsung-desc2 {
    padding-left: 1rem; } }

.alt-wrapper {
  padding: 3.5rem 3rem 0 1rem; }
  @media screen and (max-width: 39.9375em) {
    .alt-wrapper {
      padding: 2rem 1.25rem 0 1.25rem; } }

.alt-title {
  color: #000;
  font-weight: 800;
  margin-bottom: 0.5rem; }

.alt-body-text {
  font-size: 0.9rem;
  line-height: 1.5;
  color: #848589; }
  @media screen and (max-width: 39.9375em) {
    .alt-body-text {
      font-size: 0.8rem; } }

.alt-list li {
  font-size: 0.9rem;
  line-height: 1.5;
  color: #848589; }
  @media screen and (max-width: 39.9375em) {
    .alt-list li {
      font-size: 0.8rem; } }

.samsung-img-wrapper {
  padding-top: 8rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .samsung-img-wrapper {
      padding: 3rem 0.625rem 0; } }
  @media screen and (max-width: 39.9375em) {
    .samsung-img-wrapper {
      padding-top: 2rem; } }

.samsung-img-wrapper1 {
  padding: 8rem 0; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .samsung-img-wrapper1 {
      padding: 4rem 0; } }
  @media screen and (max-width: 39.9375em) {
    .samsung-img-wrapper1 {
      padding: 2rem 0; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .samsung-img-section {
    padding: 0 1rem; } }

@media screen and (max-width: 39.9375em) {
  .samsung-img-section {
    padding: 0 0.625rem; } }

@media screen and (max-width: 39.9375em) {
  .samsung-img8 {
    padding: 0 0.4rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .samsung-img8 {
    padding: 0 1rem; } }

@media screen and (max-width: 39.9375em) {
  .samsung-img9 {
    padding: 0 0.4rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .samsung-img9 {
    padding: 0 1rem; } }

@media screen and (max-width: 39.9375em) {
  .samsung-img10 {
    padding: 0 0.4rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .samsung-img10 {
    padding: 0 1rem; } }

@media screen and (max-width: 39.9375em) {
  .samsung-img11 {
    padding: 0 0.625rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .samsung-img11 {
    padding: 0; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .samsung-img11 {
    padding: 0; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .samsung-img12 {
    padding: 0; } }

@media screen and (max-width: 39.9375em) {
  .samsung-img12 {
    padding: 0 0.625rem; } }

.zurb-img1 {
  width: 80%; }
  @media screen and (max-width: 39.9375em) {
    .zurb-img1 {
      width: 83%; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .zurb-img1 {
      width: 83%;
      padding-left: 1rem; } }
  @media screen and (min-width: 50em) {
    .zurb-img1 {
      padding-left: 1rem; } }
  @media screen and (min-width: 64em) {
    .zurb-img1 {
      padding-left: 2rem; } }
  @media screen and (min-width: 80em) {
    .zurb-img1 {
      padding-left: 1rem; } }

.zurb-img2 {
  width: 23%;
  position: absolute; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .zurb-img2 {
      top: 6rem;
      right: 1.8rem; } }
  @media screen and (max-width: 39.9375em) {
    .zurb-img2 {
      top: 2.5rem;
      right: 1.25rem; } }
  @media screen and (min-width: 50em) {
    .zurb-img2 {
      top: 8rem;
      right: 1.8rem; } }
  @media screen and (min-width: 64em) {
    .zurb-img2 {
      top: 10rem;
      right: 1.8rem; } }
  @media screen and (min-width: 80em) {
    .zurb-img2 {
      top: 12rem;
      right: 0; } }

.zurb-section-partial {
  padding: 0 0 12rem 0; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .zurb-section-partial {
      padding: 0 0 5rem 0; } }
  @media screen and (max-width: 39.9375em) {
    .zurb-section-partial {
      padding: 0 0 3rem 0; } }

.zurb-img-wrapper {
  height: 1050px;
  position: relative; }

@media screen and (max-width: 39.9375em) {
  .zurb-img7 {
    padding: 0 0.625rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .zurb-img7 {
    padding: 0 1rem; } }

@media screen and (min-width: 50em) {
  .zurb-img7 {
    padding: 0 1rem; } }

@media screen and (min-width: 62.5em) {
  .zurb-img7 {
    padding: 0 1rem; } }

@media screen and (min-width: 80em) {
  .zurb-img7 {
    padding: 0; } }

.zurb-img8 {
  position: absolute;
  width: 100%;
  top: 20rem;
  left: -18rem; }

.zurb-img9 {
  position: absolute;
  width: 100%;
  top: 40rem;
  left: 0rem; }

.zurb-section-desc {
  padding-top: 8rem;
  padding-right: 5rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .zurb-section-desc {
      padding-top: 1rem;
      padding-right: 1.25rem; } }
  @media screen and (max-width: 39.9375em) {
    .zurb-section-desc {
      padding-top: 0;
      padding-right: 1.25rem; } }

.zurb-img11 {
  position: absolute;
  width: 95%;
  top: 3rem; }
  @media screen and (max-width: 39.9375em) {
    .zurb-img11 {
      position: relative;
      width: 100%;
      top: 0rem;
      padding-top: 6rem; } }

.zurb-img12 {
  position: absolute;
  width: 95%;
  top: -6rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .zurb-img12 {
      position: relative;
      width: 100%;
      top: 0rem;
      padding-top: 0; } }
  @media screen and (max-width: 39.9375em) {
    .zurb-img12 {
      position: relative;
      width: 100%;
      top: 0rem;
      padding-top: 1rem; } }

.zurb-img-wrapper1 {
  position: relative; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .zurb-img-wrapper1 {
      padding: 0 1.25rem; } }
  @media screen and (max-width: 39.9375em) {
    .zurb-img-wrapper1 {
      padding-left: 1.25rem; } }

.zurb-img-wrapper2 {
  position: relative; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .zurb-img-wrapper2 {
      padding: 0 1.25rem; } }
  @media screen and (max-width: 39.9375em) {
    .zurb-img-wrapper2 {
      padding-right: 1.25rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .myvotetoday-desc {
    padding-top: 3rem; } }

@media screen and (max-width: 39.9375em) {
  .myvotetoday-desc {
    padding-top: 3rem; } }

@media screen and (max-width: 39.9375em) {
  .myvotetoday-img2 {
    padding-top: 1rem; } }

.myvotetoday-desc1 {
  padding: 10rem 5rem 0 1.25rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .myvotetoday-desc1 {
      padding: 0rem 1rem; } }
  @media screen and (max-width: 39.9375em) {
    .myvotetoday-desc1 {
      padding: 1rem 0.625rem; } }

@media screen and (max-width: 39.9375em) {
  .myvotetoday-img3 {
    padding: 1rem 0.625rem; } }

@media screen and (max-width: 39.9375em) {
  .myvotetoday-desc2 {
    padding: 4rem 0.625rem 2rem; } }

@media screen and (min-width: 40em) {
  .myvotetoday-desc2 {
    padding-top: 1rem; } }

@media screen and (min-width: 43.75em) {
  .myvotetoday-desc2 {
    padding-top: 4rem; } }

@media screen and (min-width: 50em) {
  .myvotetoday-desc2 {
    padding-top: 10rem; } }

@media screen and (min-width: 64.375em) {
  .myvotetoday-desc2 {
    padding-top: 16rem; } }

@media screen and (min-width: 75em) {
  .myvotetoday-desc2 {
    padding-top: 22rem; } }

@media screen and (max-width: 39.9375em) {
  .myvotetoday-img4 {
    padding: 0 6rem 0 4rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .myvotetoday-img4 {
    padding-right: 4rem; } }

@media print, screen and (min-width: 64em) {
  .myvotetoday-img4 {
    padding-right: 5rem; } }

@media screen and (max-width: 39.9375em) {
  .myvotetoday-desc3 {
    padding: 0.5rem 1.25rem; } }

.myvotetoday-img-wrapper {
  padding-top: 5rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .myvotetoday-img-wrapper {
      padding-top: 3rem; } }
  @media screen and (max-width: 39.9375em) {
    .myvotetoday-img-wrapper {
      padding-top: 2rem; } }

@media screen and (max-width: 39.9375em) {
  .myvotetoday-img11 {
    padding-left: 0.625rem; } }

@media screen and (max-width: 39.9375em) {
  .myvotetoday-img12 {
    padding-right: 0.625rem; } }

@media screen and (max-width: 39.9375em) {
  .myvotetoday-img13 {
    padding-top: 2rem;
    padding-left: 0.625rem; } }

@media screen and (max-width: 39.9375em) {
  .myvotetoday-img14 {
    padding-top: 2rem;
    padding-right: 0.625rem; } }

.myvotetoday-img15 {
  position: absolute;
  width: 95%;
  top: 3rem; }
  @media screen and (max-width: 39.9375em) {
    .myvotetoday-img15 {
      position: relative;
      width: 100%;
      top: 0;
      padding-top: 5rem; } }

.myvotetoday-img16 {
  position: absolute;
  width: 95%;
  top: -6rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .myvotetoday-img16 {
      position: relative;
      width: 100%;
      top: 0; } }
  @media screen and (max-width: 39.9375em) {
    .myvotetoday-img16 {
      position: relative;
      width: 100%;
      top: 0; } }

.myvotetoday-section-desc4 {
  padding-top: 8rem;
  padding-right: 5rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .myvotetoday-section-desc4 {
      padding-top: 1rem;
      padding-right: 1.25rem; } }
  @media screen and (max-width: 39.9375em) {
    .myvotetoday-section-desc4 {
      padding-top: 0;
      padding-right: 1.25rem; } }

.myvotetoday-img-wrapper1 {
  position: relative; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .myvotetoday-img-wrapper1 {
      padding: 0 1.25rem; } }
  @media screen and (max-width: 39.9375em) {
    .myvotetoday-img-wrapper1 {
      padding-left: 1.25rem; } }

.myvotetoday-img-wrapper2 {
  position: relative; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .myvotetoday-img-wrapper2 {
      padding: 0 1.25rem; } }
  @media screen and (max-width: 39.9375em) {
    .myvotetoday-img-wrapper2 {
      padding-right: 1.25rem; } }

.scroll-topbar {
  align-items: center;
  background: none;
  max-width: none;
  overflow: hidden;
  position: fixed;
  padding: 1rem 1.25rem;
  background-color: none;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: transform 0.25s ease, background-color 0.5s ease;
  width: 100%;
  z-index: 99999; }
  @media screen and (max-width: 39.9375em) {
    .scroll-topbar {
      padding: 1rem 0.9375rem; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .scroll-topbar {
      padding: 1rem 0.9375rem; } }
  @media screen and (min-width: 64em) {
    .scroll-topbar {
      padding: 1rem 0.9375rem; } }
  .scroll-topbar.hide-nav {
    transform: translateY(-100%);
    transition: transform 0.25s ease; }
  .scroll-topbar.background {
    background-color: #fefefe; }
  .scroll-topbar.top-of-page {
    transition: padding 0.25s ease; }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .scroll-topbar.top-of-page {
        padding: 2rem 0.9375rem 1rem 0.9375rem; } }
    @media screen and (min-width: 64em) {
      .scroll-topbar.top-of-page {
        padding: 3rem 0.9375rem 1rem 0.9375rem; } }
    @media screen and (min-width: 75em) {
      .scroll-topbar.top-of-page {
        padding: 5rem 0.9375rem 1rem 0.9375rem; } }
    @media screen and (min-width: 81.25em) {
      .scroll-topbar.top-of-page {
        padding: 5rem 0 1rem 0; } }

.work-header {
  letter-spacing: -0.2px; }

.work-section-partial.secondary {
  padding: 5rem 0 0 0; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .work-section-partial.secondary {
      padding: 3rem 0 0 0; } }
  @media screen and (max-width: 39.9375em) {
    .work-section-partial.secondary {
      padding-top: 0rem; } }

.work-second-img-wrapper {
  position: relative; }

.work-second-column {
  width: 98%; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .work-second-column {
      width: 100%; } }
  @media screen and (max-width: 39.9375em) {
    .work-second-column {
      padding-top: 2rem;
      width: 100%; } }

.work-first-column {
  position: absolute;
  top: -19.5rem;
  width: 94.5%; }
  .work-first-column.last {
    padding-bottom: 7rem; }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .work-first-column.last {
        padding-bottom: 4rem; } }
    @media screen and (max-width: 39.9375em) {
      .work-first-column.last {
        padding-bottom: 3rem; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .work-first-column {
      top: -8rem;
      width: 92%; } }
  @media screen and (max-width: 39.9375em) {
    .work-first-column {
      position: relative;
      top: 0;
      padding-right: 0;
      padding-top: 2rem;
      width: 100%; } }

.work-info {
  margin-top: 1rem; }

.work-name {
  font-family: 'Karla', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.35;
  padding: 0.25rem 0 0 0;
  color: #2A2E31; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .work-name {
      font-size: 1.25rem;
      padding: 0.15rem 0 0 0; } }
  @media screen and (max-width: 39.9375em) {
    .work-name {
      font-size: 1rem;
      padding: 0; } }

.work-meta {
  font-family: 'Karla', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.35;
  color: #848589;
  padding: 0; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .work-meta {
      font-size: 1.25rem; } }
  @media screen and (max-width: 39.9375em) {
    .work-meta {
      font-size: 1rem; } }

.header-hero {
  padding-top: 5rem;
  padding-bottom: 3rem;
  z-index: 1; }
  @media screen and (max-width: 39.9375em) {
    .header-hero {
      padding-top: 2rem; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .header-hero {
      padding-top: 4rem; } }

.header-hero-text {
  color: #2A2E31;
  font-weight: 400;
  letter-spacing: -1px;
  padding-right: 1rem; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .header-hero-text {
      padding-right: 0; } }
  @media screen and (max-width: 39.9375em) {
    .header-hero-text {
      padding-right: 0; } }

.header-section {
  padding: 8rem 0 18rem 0; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .header-section {
      padding: 4rem 0 6rem 0; } }
  @media screen and (max-width: 39.9375em) {
    .header-section {
      padding: 0rem 0 4rem 0; } }

.img-link {
  display: block;
  background-color: #fefefe; }

.img-link img {
  opacity: 1.0;
  -webkit-transition: .3s ease;
  transition: opacity .3s ease; }

.img-link:hover img {
  opacity: 0.92;
  transition: opacity .3s ease; }

.more-work1,
.more-work2 {
  display: block; }

.plaid-consumer-img1 {
  width: 92%; }
  @media screen and (max-width: 39.9375em) {
    .plaid-consumer-img1 {
      width: 88%;
      padding-bottom: 1rem; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .plaid-consumer-img1 {
      width: 88%;
      padding-left: 1rem; } }
  @media screen and (min-width: 50em) {
    .plaid-consumer-img1 {
      padding-left: 1rem; } }
  @media screen and (min-width: 64em) {
    .plaid-consumer-img1 {
      padding-left: 1rem;
      width: 88%; } }
  @media screen and (min-width: 80em) {
    .plaid-consumer-img1 {
      padding-left: 1rem;
      width: 92%; } }

.plaid-consumer-img2 {
  width: 30%;
  position: absolute; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .plaid-consumer-img2 {
      top: 2.5rem;
      right: 1.8rem; } }
  @media screen and (max-width: 39.9375em) {
    .plaid-consumer-img2 {
      top: 1rem;
      right: 1.25rem; } }
  @media screen and (min-width: 50em) {
    .plaid-consumer-img2 {
      top: 3rem;
      right: 1.8rem; } }
  @media screen and (min-width: 64em) {
    .plaid-consumer-img2 {
      top: 4rem;
      right: 1.5rem; } }
  @media screen and (min-width: 80em) {
    .plaid-consumer-img2 {
      top: 5rem;
      right: 0; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .plaid-consumer-img3 {
    position: relative;
    padding-top: 5rem; } }

@media screen and (max-width: 39.9375em) {
  .plaid-consumer-img3 {
    padding-top: 2rem; } }

@media screen and (min-width: 50em) {
  .plaid-consumer-img3 {
    padding-top: 3rem; } }

@media screen and (min-width: 64em) {
  .plaid-consumer-img3 {
    position: absolute;
    width: 50rem;
    padding-left: 5rem;
    padding-top: 8rem; } }

.plaid-consumer-img4 {
  padding-bottom: 2rem; }
  @media screen and (max-width: 39.9375em) {
    .plaid-consumer-img4 {
      padding-bottom: 0rem;
      width: 49%;
      padding-right: 0.5rem;
      padding-top: 1rem; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .plaid-consumer-img4 {
      padding-bottom: 0rem;
      width: 49.5%;
      padding-right: 0.5rem;
      padding-top: 2rem; } }
  @media screen and (min-width: 64em) {
    .plaid-consumer-img4 {
      width: 30rem; } }

@media screen and (max-width: 39.9375em) {
  .plaid-consumer-img5 {
    width: 49%;
    padding-left: 0.5rem;
    padding-top: 1rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .plaid-consumer-img5 {
    width: 49.5%;
    padding-left: 0.5rem;
    padding-top: 2rem; } }

@media screen and (min-width: 64em) {
  .plaid-consumer-img5 {
    width: 30rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .plaid-consumer-img7 {
    position: relative;
    padding-top: 5rem; } }

@media screen and (max-width: 39.9375em) {
  .plaid-consumer-img7 {
    padding-top: 1rem; } }

@media screen and (min-width: 50em) {
  .plaid-consumer-img7 {
    padding-top: 3rem; } }

@media screen and (min-width: 64em) {
  .plaid-consumer-img7 {
    position: absolute;
    width: 50rem;
    padding-left: 5rem;
    padding-top: 0rem; } }

.plaid-consumer-img9 {
  padding-top: 6rem;
  padding-bottom: 6rem; }
  @media screen and (max-width: 39.9375em) {
    .plaid-consumer-img9 {
      padding-top: 2rem;
      padding-bottom: 2rem; } }

.plaid-consumer-img10 {
  padding-bottom: 6rem; }
  @media screen and (max-width: 39.9375em) {
    .plaid-consumer-img10 {
      padding-bottom: 2rem; } }

.plaid-consumer-img12 {
  padding-bottom: 2rem; }
  @media screen and (max-width: 39.9375em) {
    .plaid-consumer-img12 {
      padding-bottom: 1rem; } }

.plaid-consumer-img13 {
  padding-bottom: 2rem; }
  @media screen and (max-width: 39.9375em) {
    .plaid-consumer-img13 {
      padding-bottom: 1rem; } }

.plaid-consumer-video-bg-01 {
  display: block;
  background-color: #fdfdfd;
  text-align: center;
  width: 100%;
  max-height: 570px; }
  @media screen and (max-width: 39.9375em) {
    .plaid-consumer-video-bg-01 {
      height: 46vw;
      padding-top: 1rem; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .plaid-consumer-video-bg-01 {
      height: 46vw;
      padding-top: 0rem; } }
  @media screen and (min-width: 50em) {
    .plaid-consumer-video-bg-01 {
      height: 47vw;
      padding-top: 1rem; } }
  @media screen and (min-width: 64em) {
    .plaid-consumer-video-bg-01 {
      height: 47.3vw;
      padding-top: 3.8rem; } }

.plaid-consumer-img14 {
  padding-bottom: 0rem;
  height: auto; }
  @media screen and (max-width: 39.9375em) {
    .plaid-consumer-img14 {
      padding-top: 0rem;
      width: 80%; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .plaid-consumer-img14 {
      padding-top: 1rem;
      width: 80%; } }
  @media screen and (min-width: 64em) {
    .plaid-consumer-img14 {
      padding-top: 1rem;
      width: 70%; } }

.plaid-consumer-img15 {
  padding-bottom: 2rem; }
  @media screen and (max-width: 39.9375em) {
    .plaid-consumer-img15 {
      padding-bottom: 1rem; } }

.plaid-consumer-section-partial-top {
  padding: 8rem 0 0 0; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .plaid-consumer-section-partial-top {
      padding: 5rem 0 0 0; } }
  @media screen and (max-width: 39.9375em) {
    .plaid-consumer-section-partial-top {
      padding: 3rem 0 0 0; } }

.plaid-consumer-video-bg-02 {
  display: block;
  width: 100%;
  max-height: 570px; }

.plaid-consumer-img16 {
  height: auto;
  max-width: 570px;
  position: relative; }
  @media screen and (max-width: 39.9375em) {
    .plaid-consumer-img16 {
      width: 46vw; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .plaid-consumer-img16 {
      width: 46vw; } }
  @media screen and (min-width: 50em) {
    .plaid-consumer-img16 {
      width: 47vw; } }
  @media screen and (min-width: 64em) {
    .plaid-consumer-img16 {
      width: 47.3vw; } }
