.myvotetoday-desc {
  
  @include breakpoint(medium only) {
    padding-top: 3rem;  
  }
  
  @include breakpoint(small only) {
    padding-top: 3rem;  
  }
}

.myvotetoday-img2 {
  
  @include breakpoint(small only) {
    padding-top: 1rem;  
  }
}

.myvotetoday-desc1 {
  padding: 10rem 5rem 0 1.25rem;
  
  @include breakpoint(medium only) {
    padding: 0rem 1rem;  
  }
  
  @include breakpoint(small only) {
    padding: 1rem 0.625rem;  
  }
}

.myvotetoday-img3 {
  
  @include breakpoint(small only) {
    padding: 1rem 0.625rem;  
  }
}

.myvotetoday-desc2 {
  
  @include breakpoint(small only) {
    padding: 4rem 0.625rem 2rem;  
  }
  
  @include breakpoint(640) {
    padding-top: 1rem;  
  }
  
  @include breakpoint(700) {
    padding-top: 4rem;  
  }
  
  @include breakpoint(800) {
    padding-top: 10rem;  
  }
  
  @include breakpoint(1030) {
    padding-top: 16rem;  
  }
  
  @include breakpoint(1200) {
    padding-top: 22rem;
  }
}

.myvotetoday-img4 {
  
  @include breakpoint(small only) {
    padding: 0 6rem 0 4rem;  
  }
  
  @include breakpoint(medium only) {
    padding-right: 4rem;  
  }

  @include breakpoint(large) {
    padding-right: 5rem;  
  }
  
}

.myvotetoday-desc3 {
  
  @include breakpoint(small only) {
    padding: 0.5rem 1.25rem;  
  }
}

.myvotetoday-img-wrapper {
  padding-top: 5rem;
  
  @include breakpoint(medium only) {
    padding-top: 3rem;  
  }
  
  @include breakpoint(small only) {
    padding-top: 2rem;  
  }
}

.myvotetoday-img11 {
  
  @include breakpoint(small only) {
    padding-left: 0.625rem;
  }
}

.myvotetoday-img12 {
  
  @include breakpoint(small only) {
    padding-right: 0.625rem;
  }
}

.myvotetoday-img13 {
  
  @include breakpoint(small only) {
    padding-top: 2rem;
    padding-left: 0.625rem;
  }
}
  
.myvotetoday-img14 {
  
  @include breakpoint(small only) {
    padding-top: 2rem;
    padding-right: 0.625rem;
  }
}

.myvotetoday-img15 {
  position: absolute;
  width: 95%;
  top: 3rem;
  
  @include breakpoint(small only) {
    position: relative;
    width: 100%;
    top: 0;
    padding-top: 5rem;
  }
}

.myvotetoday-img16 {
  position: absolute;
  width: 95%;
  top: -6rem;
  
  @include breakpoint(medium only) {
    position: relative;
    width: 100%;
    top: 0; 
  }
  
  @include breakpoint(small only) {
    position: relative;
    width: 100%;
    top: 0; 
  }
}

.myvotetoday-section-desc4 {
  padding-top: 8rem;
  padding-right: 5rem;
  
  @include breakpoint(medium only){
    padding-top: 1rem;
    padding-right: 1.25rem;
  }
  
  @include breakpoint(small only) {
    padding-top: 0;
    padding-right: 1.25rem;
  }
}

.myvotetoday-img-wrapper1 {
  position: relative;
  
  @include breakpoint(medium only){
    padding: 0 1.25rem;
  }
  
  @include breakpoint(small only){
    padding-left: 1.25rem;
  }
}

.myvotetoday-img-wrapper2 {
  position: relative;
  
  @include breakpoint(medium only){
    padding: 0 1.25rem;
  }
  
  @include breakpoint(small only){
    padding-right: 1.25rem;
  }
}